import '@src/index.styl';
import { createRoot } from 'react-dom/client';
import { App } from './app';

export const init = () => {
  const container = document.querySelector('[data-app]');
  if(container) createRoot(container).render(<App />);
};

init();
