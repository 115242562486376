import '@src/base/index.styl';
import { useEffect } from 'react';
import { I18nProvider } from '@src/base/providers/i18n';
import analyticsService from '@src/base/services/analytics';
import { setInitialTheme } from '@src/base/services/theme';
import { Router } from '@src/router';

export const App = () => {
  useEffect(() => {
    setInitialTheme();
    analyticsService.init();
  }, []);

  return (
    <I18nProvider>
      <Router />
    </I18nProvider>
  );
};
